<style scoped>
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
</style>
<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Notas de Evolución</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="showModalAdd(data)">
              Agregar
            </b-button>
          </template>
          <template v-slot:body>
            <DataTable
              v-if="history"
              :data="history"
              :columns="columns"
              :fields-search="[
                'diagnoses',
                'weight',
                'height',
                'systolic_pressure',
                'diastolic_pressure',
              ]"
            >
              <template #rows="{ row, rowIndex }">
                <tr>
                  <th scope="row">{{ rowIndex + 1 }}</th>
                  <td>
                    {{ row.user.first_last_name }}
                    {{ row.user.second_last_name }},
                    {{ row.user.name }}
                  </td>
                  <td>
                    <b-button
                      v-b-toggle="`collapse-${rowIndex}`"
                      variant="primary"
                      >Ver notas de Evolución</b-button
                    >
                    <b-collapse :id="`collapse-${rowIndex}`" class="mt-2">
                      <b-card>
                        <ul
                          class="border px-2 m-0 py-2"
                          v-for="evolution in row.evolution"
                        >
                          <li>
                            <strong>Fecha:</strong> {{ evolution.date }} -
                            <strong>Hora:</strong>
                            {{ evolution.time }}
                          </li>
                          <li>
                            <ul class="p-0 m-0">
                              <li><strong>S:</strong> {{ evolution.s }}</li>
                              <li><strong>O:</strong> {{ evolution.o }}</li>
                              <li><strong>A:</strong> {{ evolution.a }}</li>
                              <li><strong>P:</strong> {{ evolution.p }}</li>
                            </ul>
                          </li>
                        </ul>
                      </b-card>
                    </b-collapse>
                  </td>
                  <td>
                    <b-button
                      v-b-toggle="`collapse-prescriptions-${rowIndex}`"
                      variant="primary"
                      >Ver Prescripciones</b-button
                    >
                    <b-collapse
                      :id="`collapse-prescriptions-${rowIndex}`"
                      class="mt-2"
                    >
                      <b-card>
                        <ul
                          class="border p-2 m-0"
                          v-for="prescription in row.prescriptions"
                        >
                          <li>
                            <strong>Farmacoterapia e indicaciones:</strong>
                            {{ prescription.farmacos }},
                          </li>
                          <li>
                            <strong>Farmacos:</strong>
                            {{ prescription.indications }}
                          </li>
                        </ul>
                      </b-card>
                    </b-collapse>
                  </td>
                  <td>
                    {{ row.created_at }}
                  </td>
                  <td>
                    <b-button-group>
                      <b-button
                        v-if="role != 'Paciente' && lastId === row.id"
                        v-user-can="'leer pacientes'"
                        variant="warning"
                        size="sm"
                        @click="showModalEdit(row)"
                        v-b-tooltip.hover
                        title="Editar"
                      >
                        <i class="ri-ball-pen-fill m-0"></i>
                      </b-button>
                      <b-button
                        v-user-can="'leer pacientes'"
                        variant="secondary"
                        size="sm"
                        @click="showModal(row)"
                      >
                        <i class="ri-eye-fill m-0"></i>
                      </b-button>
                      <b-button
                        v-user-can="'leer pacientes'"
                        variant="success"
                        size="sm"
                        @click="downloadPdf(row)"
                      >
                        <i class="ri-file-fill m-0"></i>
                      </b-button>
                    </b-button-group>
                  </td>
                </tr>
              </template>
            </DataTable>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-data" title="Historial Clínico" hide-footer>
      <ul>
        <li><b>Institucion del Sistema:</b> {{ data.institucion }}</li>
      </ul>
    </b-modal>

    <b-modal
      id="modal-edit"
      title="Notas de Evolución"
      hide-footer
      size="lg"
      no-close-on-backdrop
    >
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(onSubmitEvolutionNote)">
          <b-row>
            <div class="col-md-12">
              <p>EVOLUCIÓN</p>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>Fecha:</th>
                  <th>Hora:</th>
                  <th>Notas de Evolución (SOAP):</th>
                  <th>-</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="evolution in evolutions" :key="evolution.date">
                  <td>
                    {{ evolution.date }}
                  </td>
                  <td>
                    {{ evolution.time }}
                  </td>
                  <td>
                    <ul>
                      <li>S: {{ evolution.s }}</li>
                      <li>O: {{ evolution.o }}</li>
                      <li>A: {{ evolution.a }}</li>
                      <li>P: {{ evolution.p }}</li>
                    </ul>
                  </td>
                  <td>
                    <button
                      class="btn btn-warning"
                      @click="() => handleDeleteEvolution(evolution.notes)"
                    >
                      X
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b-form-input
                      type="date"
                      v-model="evolution.date"
                    ></b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      type="time"
                      v-model="evolution.time"
                    ></b-form-input>
                  </td>
                  <td>
                    <div class="d-flex flex-column gap-3">
                      <label class="d-flex align-items-center">
                        S:
                        <b-form-input
                          class="mb-2"
                          v-model="evolution.s"
                        ></b-form-input>
                      </label>
                      <label class="d-flex align-items-center">
                        O:
                        <b-form-input
                          class="mb-2"
                          v-model="evolution.o"
                        ></b-form-input>
                      </label>
                      <label class="d-flex align-items-center">
                        A:
                        <b-form-input
                          class="mb-2"
                          v-model="evolution.a"
                        ></b-form-input>
                      </label>
                      <label class="d-flex align-items-center">
                        P:
                        <b-form-input
                          class="mb-2"
                          v-model="evolution.p"
                        ></b-form-input>
                      </label>
                    </div>
                  </td>
                  <td>
                    <button @click="handleAddEvolution" class="btn btn-primary">
                      Agregar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-md-12">
              <p>PRESCRIPCIONES</p>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>Farmacoterapia e indicaciones:</th>
                  <th>Farmacos:</th>
                  <th>-</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="prescription in prescriptions"
                  :key="prescription.date"
                >
                  <td>
                    {{ prescription.indications }}
                  </td>
                  <td>
                    {{ prescription.farmacos }}
                  </td>
                  <td>
                    <button
                      class="btn btn-warning"
                      @click="
                        () => handleDeletePrescription(prescription.indications)
                      "
                    >
                      X
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b-form-textarea
                      v-model="prescription.indications"
                    ></b-form-textarea>
                  </td>
                  <td>
                    <b-form-textarea
                      v-model="prescription.farmacos"
                    ></b-form-textarea>
                  </td>
                  <td>
                    <button
                      @click="handleAddPrescription"
                      class="btn btn-primary"
                    >
                      Agregar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-form-group
              class="col-md-12"
              label-for="Personal de Salud"
              label="Personal de Salud:"
            >
              <b-form-select
                v-model="data.medicalID"
                plain
                :options="doctors"
                id="salud"
              >
              </b-form-select>
            </b-form-group>
          </b-row>
          <b-button type="submit" variant="primary" class="mr-2">
            Guardar
          </b-button>
          <b-button type="reset" variant="none" class="iq-bg-danger">
            Cancelar
          </b-button>
        </b-form>
      </ValidationObserver>
    </b-modal>
  </b-container>
</template>

<script>
import { Toast } from "./../../../mixins/toast";
import { xray } from "../../../config/pluginInit";
import api from "./../../../services/api";
import DataTable from "./../../../components/DataTable.vue";
import Swal from "sweetalert2";
import listCodes from "../../../Utils/codes.json";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      role: null,
      data: {},
      type: "add",
      history: [],
      columns: [
        {
          name: "Paciente",
          field: "name",
          sort: "",
        },
        {
          name: "Notas de Evolucion",
          field: "notes",
          sort: "",
        },
        {
          name: "Prescripciones",
          field: "prescriptions",
          sort: "",
        },
        {
          name: "Fecha",
          field: "created_at",
          sort: "",
        },
        {
          name: "Acciones",
          field: "actions",
          sort: "",
        },
      ],
      lastId: null,
      type: "add",
      result: {},
      results: [],
      diagnosis: {},
      diagnosses: [],
      images: [],
      doctors: [],
      fecha: "",
      diagnosis_description: "",
      diagnosis_code: "",
      codes: [],
      evolution: {
        date: " ",
        time: " ",
        notes: " ",
      },
      evolutions: [],
      prescription: {
        indications: " ",
        farmacos: " ",
      },
      prescriptions: [],
    };
  },
  filters: {
    formatDate: function (value) {
      if (!value) return "";
      const [year, month, day] = value.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  mounted() {
    //funcion que se ejcuta cuando el componente esta montado (visualizado en el navegador)
    xray.index();
    this.getData();
    this.getDoctors();
    let user = JSON.parse(localStorage.getItem("user"));
    this.role = user.role;
    this.codes = listCodes;
  },
  methods: {
    handleAddEvolution(e) {
      e.preventDefault();
      const newDate = this.evolution.date.split("-");
      this.evolution.date = `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
      this.evolutions = [...this.evolutions, this.evolution];
      this.evolution = {};
    },
    handleAddPrescription(e) {
      e.preventDefault();
      this.prescriptions = [...this.prescriptions, this.prescription];
      this.prescription = {};
    },
    handleDeleteEvolution(local_notes) {
      this.evolutions = this.evolutions.filter(
        (evolution) => evolution.notes != local_notes
      );
    },
    handleDeletePrescription(local_notes) {
      this.prescriptions = this.prescriptions.filter(
        (prescription) => prescription.indications != local_notes
      );
    },
    handleChangeCie(event) {
      const description = this.codes.find((item) =>
        item.code == event.toUpperCase() ? item : ""
      );
      if (description == undefined) return;
      this.diagnosis_description = description.description;
      this.diagnosis_code = description.code;
    },
    formatNumber(num) {
      return parseInt(num);
    },
    previewImages(event) {
      const files = event.target.files;
      this.result.images = files;
      if (files && files.length > 0) {
        this.images = [];
        Array.from(files).forEach((file) => {
          const lector = new FileReader();
          lector.onload = (e) => {
            this.images.push(e.target.result);
          };
          lector.readAsDataURL(file);
        });
      }
    },
    handleAddResult(e) {
      e.preventDefault();
      console.log(this.result.date);
      const newDate = this.result.date.split("-");
      this.result.date = `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
      this.results = [...this.results, this.result];
      this.images = [];
      this.result = {};
    },
    handleDeleteResult(local_result) {
      this.results = this.results.filter(
        (result) => result.result != local_result
      );
    },
    handleAddDiagnosis(e) {
      e.preventDefault();
      const newDiagnosis = {
        ...this.diagnosis,
        cie: this.diagnosis_code,
        description: this.diagnosis_description,
      };
      this.diagnosses = [...this.diagnosses, newDiagnosis];
      this.diagnosis = {};
    },
    handleDeleteDiagnosis(cie) {
      this.diagnosses = this.diagnosses.filter((result) => result.cie != cie);
    },
    async getDoctors() {
      try {
        const { data } = await api.get("/doctors");
        this.doctors = [
          ...data.data.map((item) => {
            return {
              value: item.id,
              text: item.name,
            };
          }),
        ];
        console.log(this.doctors);
      } catch (e) {
        console.log(e);
      }
    },
    async getData() {
      try {
        const { data } = await api.get(
          `/evolution-notes?patient_id=${this.$route.params.id}`
        );
        this.history = data.data;
        this.lastId = this.history ? this.history[0].id : null;
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Error",
        });
      }
    },
    showModal(data) {
      this.data = data;
      this.$bvModal.show("modal-data");
    },
    showModalAdd(data) {
      this.prescriptions = [];
      this.evolutions = [];
      this.data = {};
      this.type = "add";
      this.$bvModal.show("modal-edit");
      this.data = data;
    },
    showModalEdit(data) {
      this.prescriptions = [...data.prescriptions] ?? [];
      this.evolutions = [...data.evolution] ?? [];
      this.type = "edit";
      this.$bvModal.show("modal-edit");
    },
    destroy(id) {
      Swal.fire({
        icon: "warning",
        title: "Seguro de eliminar",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(`/medical-history/${id}`)
            .then((res) => {
              if (res.status === 200) {
                Toast.fire({
                  icon: "success",
                  title: "Eliminado correctamente",
                });
                this.getData();
              }
            })
            .catch((error) => {
              Toast.fire({
                icon: "error",
                title: error.message,
              });
            });
        }
      });
    },
    async onSubmitEvolutionNote() {
      const url = `/patients/${this.$route.params.id}/evolution`;
      let response;

      try {
        this.data = {
          ...this.data,
          evolution: this.evolutions,
          prescriptions: this.prescriptions,
        };
        console.log(this.data);
        if (this.type === "add") {
          response = await api.post(url, this.data);
        } else {
          response = await api.put(url, this.data);
        }

        await this.getData();
        this.$bvModal.hide("modal-edit");
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Error",
        });
      }
    },
    async onSubmitExtraData() {
      const url = `/patients/${this.$route.params.id}/extra`;
      const formData = new FormData();

      this.results.forEach((item, index) => {
        formData.append(`results[${index}][name]`, item.name);
        formData.append(`results[${index}][date]`, item.date);
        formData.append(`results[${index}][result]`, item.result);

        for (let i = 0; i < item.images.length; i++) {
          formData.append(`results[${index}][images][]`, item.images[i]);
        }
      });

      let response;

      try {
        this.data = {
          ...this.data,
          exam_results: [],
          diagnoses: this.diagnosses,
        };
        if (this.type === "add") {
          response = await api.post(url, this.data);
        } else {
          response = await api.put(url, this.data);
        }

        if (response) {
          await api.post(
            `/patients/${this.$route.params.id}/history/images`,
            formData
          );
        }
        await this.getData();
        this.$bvModal.hide("modal-edit");
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: "Error",
        });
      }
    },
    async downloadPdf(row) {
      const url = `/patients/${this.$route.params.id}/evolution-note/pdf`;
      try {
        const response = await api.post(url, { row }, { responseType: "blob" });
        const urlPDF = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = urlPDF;
        link.setAttribute("download", "Evolution-notes.pdf"); // Nombre del archivo para descarga
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Toast.fire({
          icon: "success",
          title: "PDF generado",
        });
      } catch (e) {
        Toast.fire({
          icon: "error",
          title: "Error",
        });
      }
    },
  },
};
</script>
